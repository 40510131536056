import {
  ADD_EVENT,
  //   ADD_MATCH_EVENT,
  CLEAR_EVENT,
  MODIFY_EVENT,
  REMOVE_EVENT,
} from "../types/";

const addEvent = (data) => ({
  type: ADD_EVENT,
  payload: data,
});

// const addMatchEvent = ({ data }) => ({
//   type: ADD_MATCH_EVENT,
//   payload: data,
// });

const modifyEvent = ({ data }) => {
  return { type: MODIFY_EVENT, payload: data };
};

const removeEvent = (data) => ({
  type: REMOVE_EVENT,
  payload: data,
});

const clearEvent = () => ({
  type: CLEAR_EVENT,
});

export { addEvent, modifyEvent, removeEvent, clearEvent };
