export const HOME = "/";
export const MATCH = "/matches";
export const ADD = "/add-match";
export const EDITMATCH = "/edit-match";
export const COMPETITION = "/competitions";
export const TEAMS = "/teams";
export const CREATETEAM = "/create-team";
export const VIEWTEAM = "/view-team";
export const EDITTEAM = "/edit-team";
export const SELECTTEAM = "/select-team";
export const PLAYERS = "/players";
export const CREATEPLAYER = "/create-player";
export const EDITPLAYER = "/edit-player";
export const PLAYERDETAILS = "/player-details";
export const VIEWSTATS = "/view-stats";
export const EDITSTATS = "/edit-stats";
export const CREATECOMPETITION = "/create-competition";
export const EDITCOMPETITION = "/edit-competition";
export const VIEWCOMPETITION = "/view-competition";
export const MATCHFIXTURES = "/match-fixture";
export const STARTMATCH = "/match/start-match";
export const EVENTS_TYPES = [
  "Pass",
  "Dribbles",
  "Shots",
  "Crosses",
  "Goals",
  "Assists",
  "Chance Created",
  "Free Kick",
  "Penalty",
  "Fouls",
  "Interceptions",
  "Tackles",
  "Ball Progression",
  "Blocks",
  "Clearance",
  "Duels",
  "Saves",
  "Card",
  "Catches",
];
