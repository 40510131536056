import React from "react";
import { CircularProgress } from "@material-ui/core/";

const Loading = (props) => {
  if (props.error) {
    return (
      <div>
        Error! <button onClick={props.retry}>Retry</button>
      </div>
    );
  } else if (props.pastDelay) {
    return (
      <div
        style={{
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <CircularProgress />
      </div>
    );
  } else {
    return null;
  }
};

export default Loading;

export const FullPageLoader = ({ height = "60vh" }) => {
  return (
    <div
      style={{
        minHeight: `${height}`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
      }}>
      <CircularProgress />
    </div>
  );
};
