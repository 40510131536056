import { combineReducers } from "redux";
import competition from "./competition";
import event from "./player/event";
import team from "./team";
import cards from "./cardsReducer";

const rootReducer = combineReducers({
  competition,
  team,
  event,
  cards,
});

export default rootReducer;
