import Dexie from "dexie";

const db = new Dexie("ATP");
db.version(1).stores({
  events:
    "++id,team_id,player_id,position, event, type, outcome, start_time,stop_time",
  cards:
    "&id,team_id,player_id,position,event,type,outcome,start_time,stop_time",
  matchDetails:
    "&id, match_id,match_video, match_time, home_formation, away_formation, home_players, away_players, home_subs, away_subs, goals,cards,events, substitution, details, isSubmitted, lastDateSubmitted, lastTimeSubmitted",
  substitution: "&id, in, out",
  goals:
    "&id,team_id,player_id,position,event,type,outcome,start_time,stop_time",
});

export default db;
