import { useIndexedDB } from "react-indexed-db";
export default class DataBaseApi {
  useIndexedDBInstance = useIndexedDB();
  playerMatchEvents = this.useIndexedDBInstance;

  event = this.useIndexedDBInstance;
  matchEvents = this.useIndexedDBInstance;

  constructor() {
    this.playerMatchEvents = useIndexedDB("playerMatchEvents");
    this.event = useIndexedDB("event");
    this.matchEvents = useIndexedDB("matchEvents");
  }

  addAMatchEvent(eventLog) {
    return new Promise(async (resolved, rejected) => {
      try {
        // console.log(eventLog, "EVENT LOG");
        const id = await this.matchEvents.add(eventLog);
        // console.log(id, "EVENT ID");
        // persist EVENTS in SESSION

        return resolved(id);
      } catch (reason) {
        return rejected(reason);
      }
    });
  }

  getAllMatchEvents() {
    return new Promise(async (resolved, rejected) => {
      try {
        const id = await this.event.getAll();
        return resolved(id);
      } catch (reason) {
        return rejected(reason);
      }
    });
  }

  addNewPlayerMatchEvent(allPlayer = [], matchId) {
    this.clearDb().then(() => {
      allPlayer.forEach((playerObject) => {
        const newPlayerMatchEvent = {
          matchId: matchId,
          team: playerObject.team,
          playerId: playerObject.player_id,
          first_name: playerObject.first_name,
          last_name: playerObject.last_name,
          playerPosition: undefined,
          playerDob: undefined,
          playerNumber: undefined,
          start: 0,
          minutes: 0,
          bench: 0,
        };
        this.playerMatchEvents.add(newPlayerMatchEvent);
      });
    });
  }

  clearDb() {
    return new Promise(async (resolved, rejected) => {
      try {
        await this.event.clear();
        await this.matchEvents.clear();
        await this.playerMatchEvents.clear();
        return resolved();
      } catch (reason) {
        return rejected();
      }
    });
  }

  clearEvent() {
    return new Promise(async (resolved, rejected) => {
      try {
        await this.event.clear();
        return resolved();
      } catch (reason) {
        return rejected();
      }
    });
  }

  addEventREVAMPED(eventObject) {
    return new Promise(async (resolved, rejected) => {
      try {
        const id = await this.event.add(eventObject);
        return resolved(id);
      } catch (reason) {
        return rejected(reason);
      }
    });
  }

  removeEventREVAMPED(eventObject) {
    return new Promise(async (resolved, rejected) => {
      try {
        await this.event
          .deleteRecord(eventObject.id)
          .then((item) => {
            return resolved(eventObject);
          })
          .catch((reason) => {
            return rejected(reason);
          });
      } catch (reason) {
        return rejected(reason);
      }
    });
  }

  updateEventREVAMPED(updatedEventObject) {
    return new Promise(async (resolved, rejected) => {
      try {
        this.event
          .deleteRecord(updatedEventObject.id)
          .then(async (event) => {
            const id = await this.event.add(updatedEventObject);
            if (!id) return rejected();

            return resolved(updatedEventObject);
          })
          .catch((reason) => {
            return rejected();
          });
      } catch (reason) {
        return rejected(reason);
      }
    });
  }

  getEventsREVAMPED() {
    return new Promise(async (resolved, rejected) => {
      try {
        const allEvents = await this.event.getAll();
        return resolved(allEvents);
      } catch (reason) {
        return rejected(reason);
      }
    });
  }

  getGetAllEvents = (
    competitionId = "not_passed_by_front_end",
    seasonName,
    home,
    away,
    teamA = {},
    teamB = {},
    matchId = "not_passed_by_front_end",
    matchVideo,
    playersList = [],
    seasonId
  ) => {};
}
