import { call, takeLatest, put } from "redux-saga/effects";
import {
  ADD_CARD,
  MODIFY_CARD,
  CLEAR_CARDS,
  DELETE_CARD,
} from "../actions/types";
import DataBaseApi from "../../database/DataBaseApi_dexie";

const api = new DataBaseApi();

function* addCardEvent(action) {
  const data = yield call(addCard, action.payload);
  const payload = { ...action.payload, id: data };

  yield put({ type: ADD_CARD, payload });
}

function* updateCardEvent(action) {
  const data = yield call(updateCard, action.payload);
  yield put({ type: MODIFY_CARD, payload: data });
}

function* removeCardEvent(action) {
  const data = yield call(deleteCard, action.payload);
  yield put({ type: DELETE_CARD, payload: data });
}

function* clearCardEvents() {
  yield call(clearCards);
  yield put({ type: CLEAR_CARDS });
}

const addCard = async (data) => {
  return api.addCard(data);
};

const updateCard = async (data) => {
  return api.updateCard(data);
};

const clearCards = async () => {
  return api.clearAllCards();
};

const deleteCard = async (id) => {
  return api.removeCard(id);
};

const eventSaga = [
  takeLatest(ADD_CARD, addCardEvent),
  takeLatest(DELETE_CARD, removeCardEvent),
  takeLatest(MODIFY_CARD, updateCardEvent),
  takeLatest(CLEAR_CARDS, clearCardEvents),
];

export default eventSaga;
