import {
  ADD_CARD,
  CLEAR_CARDS,
  DELETE_CARD,
  MODIFY_CARD,
} from "../actions/types";

const cards = (
  state = { action: ADD_CARD, payload: undefined },
  { type, payload }
) => {
  switch (type) {
    case ADD_CARD:
      return { ...state, action: ADD_CARD, payload: payload };

    case DELETE_CARD:
      return { ...state, action: DELETE_CARD, payload: payload };

    case MODIFY_CARD:
      return { ...state, action: MODIFY_CARD, payload: payload };

    case CLEAR_CARDS:
      return { ...state, action: CLEAR_CARDS, payload: payload };

    default:
      return state;
  }
};

export default cards;
