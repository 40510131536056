const COMPETITION = {
  FETCH_ALL: "FETCH_ALL",
  FETCH_ALL_SUCCESS: "FETCH_ALL_SUCCESS",
  ADD_COMPETITION: "ADD_COMPETITION",
};

const TEAM = {
  FETCH_TEAMS: "FETCH_TEAMS",
  FETCH_TEAMS_SUCCESS: "FETCH_TEAMS_SUCCESS",
  VIEW_TEAM: "VIEW_TEAM",
  ADD_TEAM: "ADD_TEAM",
};

const MATCH = {
  ADD_MATCH: "ADD_MATCH",
  FETCH_MATCHES: "FETCH_MATCHES",
};

const ADD_EVENT = "ADD_EVENT";
const ADD_MATCH_EVENT = "ADD_MATCH_EVENT";
const EVENT_ADDED = "EVENT_ADDED";
const REMOVE_EVENT = "REMOVE_EVENT";
const EVENT_REMOVED = "EVENT_REMOVED";
const MODIFY_EVENT = "MODIFY_EVENT";
const EVENT_MODIFIED = "EVENT_MODIFIED";
const CLEAR_EVENT = "CLEAR_EVENT";
const EVENT_CLEARED = "EVENT_CLEARED";
const ADD_CARD = "ADD_CARD";
const MODIFY_CARD = "MODIFY_CARD";
const CLEAR_CARDS = "CLEAR_CARDS";
const DELETE_CARD = "DELETE_CARD";

export {
  COMPETITION,
  TEAM,
  MATCH,
  ADD_EVENT,
  ADD_MATCH_EVENT,
  REMOVE_EVENT,
  MODIFY_EVENT,
  EVENT_ADDED,
  EVENT_REMOVED,
  EVENT_MODIFIED,
  CLEAR_EVENT,
  EVENT_CLEARED,
  DELETE_CARD,
  CLEAR_CARDS,
  MODIFY_CARD,
  ADD_CARD,
};
