import db from "./DB_dexie";
export default class DataBaseApi {
  allEvents = [];
  constructor() {
    this.allEvents = [];
  }

  async addAMatchEvent(eventData) {
    const { data } = eventData;
    const id = await db.events.add(data);
    if (data.event === "Card") {
      this.addCard({ id, ...data });
    }
    if (data.event === "Goals" || data.outcome === "Scored") {
      this.addGoal({ id, ...data });
    }
    if (data.event === "Substitution" || data.event === "Subs") {
      const { subObject } = eventData;
      this.addSub({ id, in: subObject.playerIn, out: subObject.playerOut });
    }
  }

  get allMatchEvents() {
    return this.allEvents;
  }

  async clearAllEvents() {
    await db.events.clear();
  }

  async deleteEvent(id) {
    await db.events.delete(id);
  }

  async updateEvent(data) {
    const id = data?.id;
    await db.events.update(id, data);
  }

  async addCard(eventData) {
    await db.cards.add(eventData);
  }

  async removeCard(id) {
    await db.cards.delete(id);
  }

  async clearAllCards(id) {
    await db.cards.clear();
  }

  async updateCard(data) {
    const id = data?.id;
    await db.cards.update(id, data);
  }

  // SCORES
  async addGoal(eventData) {
    await db.goals.add(eventData);
  }

  async removeGoal(id) {
    await db.goals.delete(id);
  }

  async clearGoals() {
    await db.goals.clear();
  }

  async updateGoal(data) {
    const id = data?.id;
    await db.goals.update(id, data);
  }

  // SUBSTITUTION
  async addSub(eventData) {
    await db.substitution.add(eventData);
  }

  async removeSub(id) {
    await db.substitution.delete(id);
  }

  async clearSub() {
    await db.substitution.clear();
  }

  async updateSub(data) {
    const id = data?.id;
    await db.substitution.update(id, data);
  }

  // MATCH DETAILS
  async setMatchDetails(eventData) {
    db.matchDetails.toArray().then((arr) => {
      if (arr.length < 1) {
        db.matchDetails.add(eventData);
      } else {
        this.updateMatchDetails(eventData);
      }
    });
  }

  async deleteMatchDetails(id) {
    await db.matchDetails.delete(id);
  }

  async clearAllMatchDetails() {
    await db.matchDetails.clear();
  }

  async clearAMatchEventsDetails(id) {
    db.matchDetails.update(id, {
      cards: [],
      goals: [],
      events: [],
    });
  }

  async updateMatchDetails(data) {
    await db.matchDetails.toArray().then(async (arr) => {
      if (arr.length < 1) {
        await db.matchDetails.add(data);
      } else {
        const foundMatch = arr.find((match) => match.id === data.id);
        if (foundMatch?.id) {
          await db.matchDetails.update(data.id, data);
        } else {
          await db.matchDetails.add(data);
        }
      }
    });
  }

  async clearDB() {
    // this.deleteMatchDetails();
    this.clearGoals();
    this.clearAllEvents();
    this.clearAllCards();
    this.clearSub();
  }

  async clearMatchActivities() {
    this.clearGoals();
    this.clearAllEvents();
    this.clearAllCards();
    this.clearSub();
  }
}

const DBApi = new DataBaseApi();
export { DBApi };
