import { call, takeLatest, put } from "redux-saga/effects";
import { addEvent, removeEvent, modifyEvent } from "../../actions/player/event";
import {
  ADD_EVENT,
  EVENT_ADDED,
  REMOVE_EVENT,
  MODIFY_EVENT,
  EVENT_REMOVED,
  EVENT_MODIFIED,
  CLEAR_EVENT,
  EVENT_CLEARED,
} from "../../actions/types";
import DataBaseApi from "../../../database/DataBaseApi_dexie";

const api = new DataBaseApi();

function* addEventAsync(action) {
  const data = yield call(asyncAddOperation, action.payload);
  const payload = { ...action.payload, id: data };
  yield put({ type: EVENT_ADDED, payload });
}

function* updateEventAsync(action) {
  const data = yield call(asyncUpdateOperation, action.payload);
  yield put({ type: EVENT_MODIFIED, payload: data });
}

function* removeEventAsync(action) {
  const data = yield call(asyncRemoveOperation, action.payload);
  yield put({ type: EVENT_REMOVED, payload: data });
}

function* clearEventAsync() {
  yield call(asyncClearEvents);
  yield put({ type: EVENT_CLEARED });
}

const asyncAddOperation = async (data) => {
  return api.addAMatchEvent(data);
};

const asyncUpdateOperation = async (data) => {
  return api.updateEvent(data);
};

const asyncRemoveOperation = async (id) => {
  return api.deleteEvent(id);
};

const asyncClearEvents = async () => {
  return api.clearAllEvents();
};

const eventSaga = [
  takeLatest(ADD_EVENT, addEventAsync),
  takeLatest(REMOVE_EVENT, removeEventAsync),
  takeLatest(MODIFY_EVENT, updateEventAsync),
  takeLatest(CLEAR_EVENT, clearEventAsync),
];

export default eventSaga;
