import competitionSaga from "./competitonSaga";
import teamSaga from "./teamSaga";
import cardSaga from "./cardSaga";
import matchSaga from "./matchSaga";
import eventSaga from "./player/eventSaga";
import { all } from "redux-saga/effects";

export default function* rootSaga() {
  yield all([
    ...competitionSaga,
    ...teamSaga,
    ...matchSaga,
    ...eventSaga,
    ...cardSaga,
  ]);
}
