export const regionOptions = [
	{
		value: 'West Africa',
		label: ' West Africa',
		link: 'wa',
	},
	{
		value: 'East Africa',
		label: 'East Africa',
		link: 'ea',
	},
	{
		value: 'North Africa',
		label: 'North Africa',
		link: 'na',
	},
	{
		value: 'South Africa',
		label: 'South Africa',
		link: 'sa',
	},
	{
		value: 'Central Africa',
		label: 'Central Africa',
		link: 'ca',
	},
];

export const options = [
	{ value: 'Benin', label: 'Benin', countryCode: 'BJ', link: 'wa' },
	{
		value: 'Burkina Faso',
		label: 'Burkina Faso',
		countryCode: 'BF',
		link: 'wa',
	},
	{ value: 'Cabo Verde', label: 'Cabo Verde', countryCode: 'CV', link: 'wa' },
	{
		value: "Cote D'Ivoire",
		label: "Cote D'Ivoire",
		countryCode: 'CI',
		link: 'wa',
	},
	{ value: 'Ghana', label: 'Ghana', countryCode: 'GH', link: 'wa' },
	{ value: 'Gambia', label: 'Gambia', countryCode: 'GM', link: 'wa' },
	{ value: 'Guinea', label: 'Guinea', countryCode: 'GN', link: 'wa' },
	{
		value: 'Guinea-Bissau',
		label: 'Guinea-Bissau',
		countryCode: 'GW',
		link: 'wa',
	},
	{ value: 'Liberia', label: 'Liberia', countryCode: 'LR', link: 'wa' },
	{ value: 'Mali', label: 'Mali', countryCode: 'ML', link: 'wa' },
	{ value: 'Mauritania', label: 'Mauritania', countryCode: 'MR', link: 'wa' },
	{ value: 'Niger', label: 'Niger', countryCode: 'NE', link: 'wa' },
	{ value: 'Nigeria', label: 'Nigeria', countryCode: 'NG', link: 'wa' },
	{ value: 'Senegal', label: 'Senegal', countryCode: 'SN', link: 'wa' },
	{
		value: 'Sierra Leone',
		label: 'Sierra Leone',
		countryCode: 'SL',
		link: 'wa',
	},
	{ value: 'Togo', label: 'Togo', countryCode: 'TG', link: 'wa' },
	{ value: 'Burundi', label: 'Burundi', countryCode: 'BI', link: 'ea' },
	{ value: 'Comoros', label: 'Comoros', countryCode: 'KM', link: 'ea' },
	{ value: 'Djibouti', label: 'Djibouti', countryCode: 'DJ', link: 'ea' },
	{ value: 'Eritrea', label: 'Eritrea', countryCode: 'ER', link: 'ea' },
	{ value: 'Ethiopia', label: 'Ethiopia', countryCode: 'ET', link: 'ea' },
	{ value: 'Kenya', label: 'Kenya', countryCode: 'KE', link: 'ea' },
	{ value: 'Madagascar', label: 'Madagascar', countryCode: 'MG', link: 'ea' },
	{ value: 'Malawi', label: 'Malawi', countryCode: 'MW', link: 'ea' },
	{ value: 'Mauritius', label: 'Mauritius', countryCode: 'MU', link: 'ea' },
	{ value: 'Mozambique', label: 'Mozambique', countryCode: 'MZ', link: 'ea' },
	{ value: 'Rwanda', label: 'Rwanda', countryCode: 'RW', link: 'ea' },
	{ value: 'Réunion', label: 'Réunion', countryCode: 'RE', link: 'ea' },
	{ value: 'Seychelles', label: 'Seychelles', countryCode: 'SC', link: 'ea' },
	{ value: 'Somalia', label: 'Somalia', countryCode: 'SO', link: 'ea' },
	{ value: 'Tanzania', label: 'Tanzania', countryCode: 'TZ', link: 'ea' },
	{ value: 'Uganda', label: 'Uganda', countryCode: 'UG', link: 'ea' },
	{ value: 'Zambia', label: 'Zambia', countryCode: 'ZM', link: 'ea' },
	{ value: 'Zimbabwe', label: 'Zimbabwe', countryCode: 'ZW', link: 'ea' },

	{ value: 'Algeria', label: 'Algeria', countryCode: 'DZ', link: 'na' },
	{ value: 'Egypt', label: 'Egypt', countryCode: 'EG', link: 'na' },
	{ value: 'Libya', label: 'Libya', countryCode: 'LY', link: 'na' },
	{ value: 'Morocco', label: 'Morocco', countryCode: 'MA', link: 'na' },
	{ value: 'Sudan', label: 'Sudan', countryCode: 'SD', link: 'na' },
	{ value: 'Tunisia', label: 'Tunisia', countryCode: 'TN', link: 'na' },
	{
		value: 'Western Sahara',
		label: 'Western Sahara',
		countryCode: 'EH',
		link: 'na',
	},

	{ value: 'Angola', label: 'Angola', countryCode: 'AO', link: 'sa' },
	{ value: 'Botswana', label: 'Botswana', countryCode: 'BW', link: 'sa' },
	{ value: 'Lesotho', label: 'Lesotho', countryCode: 'LS', link: 'sa' },
	{ value: 'Malawi', label: 'Malawi', countryCode: 'MW', link: 'sa' },
	{ value: 'Mozambique', label: 'Mozambique', countryCode: 'MZ', link: 'sa' },
	{ value: 'Namibia', label: 'Namibia', countryCode: 'NA', link: 'sa' },
	{
		value: 'South Africa',
		label: 'South Africa',
		countryCode: 'ZA',
		link: 'sa',
	},
	{ value: 'Swaziland', label: 'Swaziland', countryCode: 'SZ', link: 'sa' },
	{ value: 'Zambia', label: 'Zambia', countryCode: 'ZM', link: 'sa' },
	{ value: 'Zimbabwe', label: 'Zimbabwe', countryCode: 'ZW', link: 'sa' },

	{ value: 'Cameroon', label: 'Cameroon', countryCode: 'CM', link: 'ca' },
	{
		value: 'Central African Republic',
		label: 'Central African Republic',
		countryCode: 'CF',
		link: 'ca',
	},
	{ value: 'Chad', label: 'Chad', countryCode: 'TD', link: 'ca' },
	{
		value: 'Democratic Republic of the Congo',
		label: 'Democratic Republic of the Congo',
		countryCode: 'CD',
		link: 'ca',
	},
	{
		value: 'Republic of the Congo',
		label: 'Republic of the Congo',
		countryCode: 'CG',
		link: 'ca',
	},
	{
		value: 'Equatorial Guinea',
		label: 'Equatorial Guinea',
		countryCode: 'GQ',
		link: 'ca',
	},
	{ value: 'Gabon', label: 'Gabon', countryCode: 'GA', link: 'ca' },
	{
		value: 'Sao Tome And Principe',
		label: 'Sao Tome And Principe',
		countryCode: 'ST',
		link: 'ca',
	},
];
